import { template as template_b2b99cf0934a4649a208fa701f453040 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import mgzs1 from '../assets/images/mg-zs-1.jpg';
import mgzs2 from '../assets/images/mg-zs-2.jpg';
import mgzs3 from '../assets/images/mg-zs-3.jpg';
export interface ImagesSignature {
    Element: HTMLElement;
}
const Images: TOC<ImagesSignature> = template_b2b99cf0934a4649a208fa701f453040(`
  <h2>MG ZS</h2>
  <div class="images">
    <img src={{mgzs1}} alt="" />
    <img src={{mgzs2}} alt="" />
    <img src={{mgzs3}} alt="" />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Images;
