import { template as template_c36bce5a4d8448b0a0773c4192c88bb7 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
import Header from '../components/header';
import Footer from '../components/footer';
interface ApplicationRouteSignature {
}
const Template: TOC<ApplicationRouteSignature> = template_c36bce5a4d8448b0a0773c4192c88bb7(`
  <Header />
  {{outlet}}
  <Footer />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(Template);
