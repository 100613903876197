import { template as template_2f9060b6fc56491fa8f062e75629a9ff } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export interface EmailSignature {
    Element: HTMLAnchorElement;
}
const Email: TOC<EmailSignature> = template_2f9060b6fc56491fa8f062e75629a9ff(`
  <a href="mailto:ladislavberan@seznam.cz" class="email">
    ladislavberan@seznam.cz
  </a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Email;
