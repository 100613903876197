import { template as template_bdb7c64136b34b8187f865ca84eaae5d } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export interface PhoneSignature {
    Element: HTMLAnchorElement;
}
const Phone: TOC<PhoneSignature> = template_bdb7c64136b34b8187f865ca84eaae5d(`
  <a href="tel:+420724237845" class="phone" ...attributes>
    +420 724 237 845
  </a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Phone;
