import { template as template_ae9810d08a574bf6a4a220b9174bab99 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export interface AddressSignature {
    Element: HTMLDivElement;
}
const Address: TOC<AddressSignature> = template_ae9810d08a574bf6a4a220b9174bab99(`
  <div class="address">
    Horní Chvatliny 15, Zásmuky, 281&nbsp;44
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Address;
